<template>
    <img 
        ref="image"
        :src="url"
        v-bind="attrs"
    />
</template>

<script>
    import { computed, defineComponent, onMounted, ref, toRaw, watch, watchEffect } from 'vue';
    import SVGInject from '@iconfu/svg-inject';
    
    export default defineComponent({
        name: 'SvgInjector',
        props: {
            src: {
                type: String,
                required: true
            },
            options: {
                type: Object,
                default: () => ({})
            }
        },
        setup(props, { attrs }) {
            const image = ref(null);
            const url = computed(() => {
                const path = props.src
                    .replace('@/', '')
                    .replace('.svg', '')
                ;
                try {
                    return require(`@/${path}.svg`)
                } catch(error) {
                    console.error(`Cannot find icon '${props.src}'`);
                }
            });

            function injectSvg() {
                SVGInject(image.value, {
                    ...props.options,
                    beforeInject(img, svg) {
                        for (const key in toRaw(attrs)) {
                            if (Object.hasOwnProperty.call(toRaw(attrs), key)) {
                                svg[key.toLocaleLowerCase()] = attrs[key];
                                
                            }
                        }
                    }
                });
            }
            
            onMounted(() => {
                try {
                    injectSvg(image.value);
                } catch (error) {
                    consosole.log("Couldn't load any svg");
                }
            });


            return {
                url,
                image,
                injectSvg,
                attrs
            };
        }
    });
</script>