import EndpointPrivate from "../EndpointPrivate";

class Qr extends EndpointPrivate {
	buildUrl({ param }) {
		return ['food', 'v1', 'qrs', param];		
	}
};

export default new Qr;

