<template>
    <!-- <span> -->
        <SvgInjector
            v-bind="attrs"
            :src="`@/assets/icons/${name}.svg`"
            :options="options"
        />
    <!-- </span> -->
</template>

<script>
    import { ref } from 'vue';
    import SvgInjector from '../svg-injector';
    
    export default {
        name: 'Icon',
        components: {
            SvgInjector
        },
        props: {
            name: {
                type: String,
                required: true
            }
        },
        setup(props, { attrs }) {
            const options = ref({
                afterLoad(svg) {
                    const paths = svg.querySelectorAll('path');
                    paths.forEach(path => {
                        if (path.hasAttribute('stroke')) {
                            path.setAttribute('stroke', 'currentColor');
                        }
                        
                        // if (path.hasAttribute('fill')) {
                        //     path.setAttribute('fill', 'currentColor');
                        // }
                    });
                }
            });
            return {
                options,
                attrs
            };
        }
    }
</script>